import React from "react";
import styled from "styled-components";
import { colors } from "../util/Colors";

const WebsiteLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: ${colors.lightGreen};
    width: 200px;

    &:hover {
      fill: ${colors.mediumGreen};
      cursor: pointer;
    }
  }
`;

function Website() {
  return (
    <WebsiteLink
      href="https://xd.adobe.com/view/0cffae14-25d5-4d92-8a43-a961e6710026-59ac/"
      rel="noopener"
      target="_blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 512 512"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <path d="M477.858 8.533H34.142C15.317 8.533 0 23.851 0 42.675v281.591a8.536 8.536 0 008.533 8.533h494.933a8.536 8.536 0 008.533-8.533V42.675C512 23.851 496.683 8.533 477.858 8.533zm17.075 307.2H17.067V42.675c0-9.421 7.663-17.075 17.075-17.075h443.716c9.412 0 17.075 7.654 17.075 17.075v273.058z"></path>
        <path d="M503.467 315.733H8.533A8.536 8.536 0 000 324.266v42.658c0 18.825 15.317 34.142 34.142 34.142h443.716c18.825 0 34.142-15.317 34.142-34.142v-42.658a8.536 8.536 0 00-8.533-8.533zm-8.534 51.192c0 9.421-7.663 17.075-17.075 17.075H34.142c-9.412 0-17.075-7.654-17.075-17.075V332.8h477.867v34.125z"></path>
        <path d="M281.6 349.867h-51.2c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h51.2c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533zM469.333 42.667H42.667a8.536 8.536 0 00-8.533 8.533v273.067a8.536 8.536 0 008.533 8.533h426.667a8.536 8.536 0 008.533-8.533V51.2c0-4.71-3.823-8.533-8.534-8.533zM460.8 315.733H51.2v-256h409.6v256zM366.933 452.267H145.067c-14.114 0-25.6 11.486-25.6 25.6s11.486 25.6 25.6 25.6h221.867c14.114 0 25.6-11.486 25.6-25.6s-11.487-25.6-25.601-25.6zm0 34.133H145.067c-4.71 0-8.533-3.831-8.533-8.533s3.823-8.533 8.533-8.533h221.867c4.71 0 8.533 3.831 8.533 8.533s-3.823 8.533-8.534 8.533z"></path>
        <path d="M349.867 452.267c-28.314 0-42.667-20.096-42.667-59.733a8.536 8.536 0 00-8.533-8.533h-85.333a8.536 8.536 0 00-8.533 8.533c0 39.637-14.353 59.733-42.667 59.733a8.536 8.536 0 00-8.533 8.533 8.536 8.536 0 008.533 8.533h187.733a8.536 8.536 0 008.533-8.533 8.536 8.536 0 00-8.533-8.533zm-146.168 0c10.505-11.639 16.691-28.971 17.937-51.2h68.736c1.237 22.229 7.424 39.561 17.937 51.2h-104.61zM407.757 259.959l-20.599-20.599 11.46-11.46a8.542 8.542 0 002.244-8.124 8.543 8.543 0 00-5.82-6.084l-85.333-25.6a8.52 8.52 0 00-8.482 2.142 8.508 8.508 0 00-2.142 8.482l25.6 85.333a8.525 8.525 0 006.084 5.828c2.901.717 5.999-.12 8.124-2.244l12.066-12.075 20.599 20.608c1.596 1.604 3.772 2.5 6.033 2.5s4.437-.896 6.033-2.5l24.132-24.141a8.524 8.524 0 00.001-12.066zm-30.166 18.108l-18.756-18.765a11.1 11.1 0 00-7.876-3.26 11.13 11.13 0 00-7.876 3.251l-6.161 6.161-16.939-56.465 56.465 16.939-6.605 6.605a9.61 9.61 0 00-2.816 6.827 9.568 9.568 0 002.833 6.835l19.797 19.797-12.066 12.075zM358.4 76.8H119.467a8.536 8.536 0 00-8.533 8.533V128a8.536 8.536 0 008.533 8.533H358.4a8.536 8.536 0 008.533-8.533V85.333A8.536 8.536 0 00358.4 76.8zm-8.533 42.667H128v-25.6h221.867v25.6zM221.867 170.667H153.6a8.536 8.536 0 00-8.533 8.533v34.133a8.536 8.536 0 008.533 8.533h68.267a8.536 8.536 0 008.533-8.533V179.2a8.536 8.536 0 00-8.533-8.533zm-8.534 34.133h-51.2v-17.067h51.2V204.8z"></path>
        <path d="M358.4 76.8H119.467a8.536 8.536 0 00-8.533 8.533v162.133a8.536 8.536 0 008.533 8.533h203.145a8.5 8.5 0 006.844-3.439 8.533 8.533 0 001.331-7.552l-10.803-36.019 35.968 10.795a8.51 8.51 0 007.543-1.331 8.512 8.512 0 003.439-6.844V85.333c-.001-4.71-3.824-8.533-8.534-8.533zm-8.533 123.341L309.7 188.092a8.52 8.52 0 00-8.482 2.142 8.524 8.524 0 00-2.142 8.491l12.066 40.209H128V93.867h221.867v106.274z"></path>
      </svg>
    </WebsiteLink>
  );
}

export default Website;
